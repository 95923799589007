import React from "react";
import { Switch, Route } from "react-router-dom";
import Appointments from "features/Appointments/Appointments";
import ProviderAppointmentsPage from "features/Appointments/biportal/ProviderAppointments";
import AppointmentForm from "features/Appointments/biportal/AppointmentForms/AppointmentForm";
import ReviewProviderDay from "features/Appointments/biportal/AppointmentForms/ReviewProviderDay";
import Login from "features/Login/Login";
import Logout from "features/Logout/Logout";
import UnverifiedUser from "features/UnverifiedUser/UnverifiedUser";
import Main from "features/Main/Main";
import PasswordResetSuccessPage from "features/PasswordReset/PasswordResetSuccessPage";
import CancelAppointment from "features/Appointments/biportal/AppointmentForms/CancelAppointment";
import EditAppointment from "features/Appointments/biportal/AppointmentForms/EditAppointment";
import PrivateRoute from "features/PrivateRoute";
import MsalPrivateRoute from "MsalPrivateRoute";
import MsalLogin from "msal/MsalLogin";
import {
  ClientAppointmentFlow,
  ClientReschedule,
  ClientRescheduleConfirm,
  ClientRescheduleBusy,
  ClientRescheduled,
  ClientRescheduleProblem,
  ClientRescheduleNotAvailable,
  ClientRescheduleLate,
  ClientCancel,
  ClientCancelDetails,
  ClientCancelReview,
  ClientCancelLate,
  ClientCancelled,
} from "features/Appointments/Calendar/PatientPortalAppointmentForms";
import {
  PatientAvailabiity,
  PatientAvailabilityBuilder,
  PatientAvailabiityDay,
  PateintAvailabilityReview,
  PatientAvailabilityClose,
  PatientAvailabilityStart,
  PatientAvailabilityBuilderReview,
  EditPatientAvailabilityBuilderReview,
  RedirectAvailability,
} from "features/Appointments/Calendar/PatientAvailability";
import Availability from "features/Appointments/Availability/Availability";
import AllAppointmentsList from "features/Appointments/Calendar/Supporting-Components/AllAppointmentsList";
import ClientCancelError from "features/Appointments/Calendar/PatientPortalAppointmentForms/CancelAppointments/ClientCancelError";

const Routes = () => (
  <Switch>
    <Route path="/" exact component={Main} />
    <Route path="/unverified_user" exact component={UnverifiedUser} />
    <Route path="/login" component={Login} />
    <Route path="/logout" component={Logout} />
    <Route
      path="/password-reset-success"
      component={PasswordResetSuccessPage}
    />
    <Route path="/provider" component={MsalLogin} />

    <MsalPrivateRoute path="/add-new-appointment" component={AppointmentForm} />
    <MsalPrivateRoute
      path="/cancel-appointment"
      component={CancelAppointment}
    />
    <MsalPrivateRoute
      path="/providerappointments"
      component={ProviderAppointmentsPage}
    />
    <MsalPrivateRoute path="/edit-appointment" component={EditAppointment} />
    <MsalPrivateRoute path="/edit-appointment" component={EditAppointment} />
    <MsalPrivateRoute path="/confirm-schedule" component={ReviewProviderDay} />

    <PrivateRoute path="/appointments" component={Appointments} />
    <PrivateRoute path="/avail" component={Availability} />
    {/*Client Availability Flow */}
    <PrivateRoute
      path="/redirect-availability"
      component={RedirectAvailability}
    />
    <PrivateRoute
      path="/appointments-availability"
      component={PatientAvailabiity}
    />
    <PrivateRoute
      path="/appointments-availability-start"
      component={PatientAvailabilityStart}
    />
    <PrivateRoute
      path="/appointments-availability-builder"
      component={PatientAvailabilityBuilder}
    />
    <PrivateRoute
      path="/appointments-availability-days"
      component={PatientAvailabiityDay}
    />
    <PrivateRoute
      path="/appointments-availability-review"
      component={PateintAvailabilityReview}
    />
    <PrivateRoute
      path="/appointments-availability-builder-review"
      component={PatientAvailabilityBuilderReview}
    />
    <PrivateRoute
      path="/appointments-availability-close"
      component={PatientAvailabilityClose}
    />
    <PrivateRoute
      path="/edit-appointments-availiability"
      component={EditPatientAvailabilityBuilderReview}
    />
    <PrivateRoute path="/client-appointments" component={AllAppointmentsList} />
    {/*Client Appointment Flow*/}
    <PrivateRoute
      path="/client-appointment-redirect"
      component={ClientAppointmentFlow}
    />
    {/*Client Reschedule Flow */}
    <PrivateRoute
      path="/client-reschedule-appointment"
      component={ClientReschedule}
    />
    <PrivateRoute
      path="/client-reschedule-confirm"
      component={ClientRescheduleConfirm}
    />
    <PrivateRoute
      path="/client-reschedule-busy"
      component={ClientRescheduleBusy}
    />
    <PrivateRoute path="/client-rescheduled" component={ClientRescheduled} />
    <PrivateRoute
      path="/client-reschedule-problem"
      component={ClientRescheduleProblem}
    />
    <PrivateRoute
      path="/client-reschedule-not-available"
      component={ClientRescheduleNotAvailable}
    />
    <PrivateRoute
      path="/client-reschedule-late"
      component={ClientRescheduleLate}
    />
    {/*Client Cancel Flow */}
    <PrivateRoute
      path="/client-cancel-details"
      component={ClientCancelDetails}
    />
    <PrivateRoute path="/client-cancel-appointment" component={ClientCancel} />
    <PrivateRoute path="/client-cancel-review" component={ClientCancelReview} />
    <PrivateRoute path="/client-cancel-late" component={ClientCancelLate} />
    <PrivateRoute path="/client-cancel-problem" component={ClientCancelError} />
    <PrivateRoute
      path="/client-appointment-cancelled"
      component={ClientCancelled}
    />
  </Switch>
);

export default Routes;
